<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l lhx40">
        <el-input
          class="w300 mr10 mb20"
          placeholder="请输入用戶名稱/ID/手機號碼"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>
        <div>
          <el-button type="primary" @click="search">搜尋</el-button>
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            label="用戶ID"
            width="120"
            prop="realname"
          ></el-table-column>
          <el-table-column prop="title" label="商家名稱"></el-table-column>
          <el-table-column
            label="商家地址"
            prop="address"
            width="200"
          ></el-table-column>
          <el-table-column
            label="聯繫方式"
            prop="contact"
            width="120"
          ></el-table-column>
          <el-table-column label="營業證明">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.image"
                style="width: 60px; height: 60px"
                :src="constants.imgUrl + scope.row.image"
                :preview-src-list="[constants.imgUrl + scope.row.image]"
                fit="contain"
              ></el-image>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="提交時間" width="180">
            <template slot-scope="scope">{{
              scope.row.create_time | formatTime
            }}</template>
          </el-table-column>
          <el-table-column label="審核意見">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.opinion"
                placement="top-start"
                title="備註"
                width="200"
                trigger="hover"
                :content="scope.row.opinion"
              >
                <div class="c-note" slot="reference">
                  {{ scope.row.opinion }}
                </div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="審核狀態">
            <template slot-scope="scope">
              <!-- //0.待審核,1已通過,2.未通過 -->
              <el-tag v-if="scope.row.state == 0">待審核</el-tag>
              <el-tag type="success" v-if="scope.row.state == 1">已通過</el-tag>
              <el-tag type="danger" v-if="scope.row.state == 2">未通過</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.state == 0"
                type="primary"
                @click="openDialog(scope.$index)"
                >審核</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <checkDialog
      @close="showDialog = false"
      :item="curItem"
      :show="showDialog"
      @confirm="checkShop"
      type="edit"
    ></checkDialog>
  </div>
</template>
<script>
import { calTableHeight } from "@/common/tool"
import checkDialog from "./components/checkDialog.vue"
export default {
  components: { checkDialog },
  data() {
    return {
      showDialog: false,
      loadList: false,
      curItem: {},
      curIndex: -1,
      type: "create",
      filters: {
        title: "",
      },
      tableData: {
        data: [{ name: "111" }],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.user.getShops({ page, ...this.filters })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    openDialog(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showDialog = true
    },
    async checkShop(data) {
      let res = await this.api.user.checkShop(data)
      if (res && res.code == 0) {
        this.$message({
          message: "審核成功",
          type: "success",
        })
        this.getDataList(this.tableData.current_page)
        this.showDialog = false
      }
    },
    /**
     * @method: resetFilters 重置筛选条件
     */
    resetFilters() {
      this.filters = {
        title: "",
      }
    },
    search() {
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    togglePublish(value, index) {
      if (value == 1) {
        this.unPublish(index)
      } else {
        this.publish(index)
      }
    },
    async unPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchHomeIndex(id, 1)
      if (res && res.code == 0) {
        console.log(res.data)
        // this.tableData.data[index].show = 1;
        this.$message({
          message: "隱藏成功",
          type: "success",
        })
      }
    },
    async publish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.ads.switchHomeIndex(id, 0)
      if (res && res.code == 0) {
        // this.tableData.data[index].show = 0;
        this.$message({
          message: "顯示成功",
          type: "success",
        })
      }
    },
  },
}
</script>
<style scoped>
.c-note {
  line-height: 20px;
  overflow: hidden;
  height: 40px;
}
/deep/.el-image-viewer__wrapper {
    position: fixed;
    top: 91px;
    right: 0;
    bottom: 0;
    left: 161px;
}
/deep/.el-icon-circle-close{
  color: #fff;
}
</style>
